<script lang="ts">
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';
    import * as Card from '$lib/components/ui/card';
    import { Button } from '$lib/components/ui/button';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import UsersIcon from '@/Icons/UsersIcon.svelte';
    import ArrowTrendingDown from '@/Icons/ArrowTrendingDown.svelte';
    import EllipsisHorizontalIcon from '@/Icons/EllipsisHorizontalIcon.svelte';

    interface User {
        id: number;
        username: string;
        name: string;
        avatar: string;
    }

    interface ReferralNode {
        name: string;
        id: number;
        username: string;
        avatar: string;
        isLeaf: boolean;
    }

    interface Referrals {
        nodes: ReferralNode[];
    }

    interface LevelContext {
        userId: number;
        userName: string;
        currentLevel: number;
        referrals: Referrals;
        minimized: boolean;
        selectedUser: ReferralNode | null;
    }

    interface ErrorState {
        message: string | null;
        type: 'error' | 'info' | null;
    }

    export let networkOwner: User;
    export let referrer: User | null;
    export let referrals: Referrals;
    export let referralTreeDepth: number;
    export let directReferralsCount: number;

    const levelContexts = writable<LevelContext[]>([]);
    const isLoading = writable(false);
    const error = writable<string | null>(null);
    const errorState = writable<ErrorState>({ message: null, type: null });

    let isInitialLoading = true;
    let authUserId: string;

    function truncateName(name: string, maxLength: number = 13): string {
        return name.length > maxLength ? name.slice(0, maxLength) + '..' : name;
    }

    async function loadReferrals(userId: number, level: number) {
        isLoading.set(true);
        error.set(null);
        try {
            const response = await fetch(`/api/v1/referrals/${userId}/${level}/${level}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: Referrals = await response.json();
            return data;
        } catch (err) {
            console.error('Error loading referrals:', err);
            error.set(err instanceof Error ? err.message : 'An unexpected error occurred');
            return { nodes: [] };
        } finally {
            isLoading.set(false);
        }
    }

    async function initialLoad() {
        isInitialLoading = true;
        isLoading.set(true);
        error.set(null);
        try {
            const initialReferrals = await loadReferrals(networkOwner.id, 1);
            levelContexts.set([
                {
                    userId: networkOwner.id,
                    userName: networkOwner.name,
                    currentLevel: 1,
                    referrals: initialReferrals,
                    minimized: false,
                    selectedUser: null,
                },
            ]);
        } catch (err) {
            console.error('Error in initial data load:', err);
            error.set(err instanceof Error ? err.message : 'An unexpected error occurred during initial load');
        } finally {
            isLoading.set(false);
            isInitialLoading = false;
        }
    }

    async function loadLevelBelow() {
        isLoading.set(true);
        errorState.set({ message: null, type: null });
        try {
            const currentContext = $levelContexts[$levelContexts.length - 1];
            const nextLevel = currentContext.currentLevel + 1;
            const data = await loadReferrals(currentContext.userId, nextLevel);

            if (data.nodes.length === 0) {
                // No more levels to load
                errorState.set({ message: 'No more levels to load.', type: 'info' });
                return;
            }

            levelContexts.update((contexts) => [
                ...contexts,
                {
                    userId: currentContext.userId,
                    userName: currentContext.userName,
                    currentLevel: nextLevel,
                    referrals: data,
                    minimized: false,
                    selectedUser: null,
                },
            ]);
        } catch (err) {
            console.error('Error loading level below:', err);
            errorState.set({
                message: err instanceof Error ? err.message : 'An unexpected error occurred',
                type: 'error',
            });
        } finally {
            isLoading.set(false);
        }
    }

    async function loadNextLevel(selectedUser: ReferralNode) {
        isLoading.set(true);
        error.set(null);
        try {
            const data = await loadReferrals(selectedUser.id, 1);

            levelContexts.update((contexts) => {
                const newContexts = contexts.map((context, index) =>
                    index === contexts.length - 1
                        ? { ...context, minimized: true, selectedUser: selectedUser } // Store selected user
                        : context
                );
                return [
                    ...newContexts,
                    {
                        userId: selectedUser.id,
                        userName: selectedUser.name,
                        currentLevel: 1,
                        referrals: data,
                        minimized: false,
                        selectedUser: null,
                    },
                ];
            });
        } catch (err) {
            console.error('Error loading next level:', err);
            error.set(err instanceof Error ? err.message : 'An unexpected error occurred');
        } finally {
            isLoading.set(false);
        }
    }

    function closeLevel(index: number) {
        levelContexts.update((contexts) => {
            const newContexts = contexts.slice(0, index);
            if (newContexts.length > 0) {
                newContexts[newContexts.length - 1].minimized = false;
            }
            return newContexts;
        });
        // Reset the error state when closing a level
        errorState.set({ message: null, type: null });
    }

    function showStats() {
        console.log('Showing stats...');
        // Implement stats display logic here
    }

    function visitProfile(username: string) {
        window.open(`/${username}`, '_blank');
    }

    onMount(() => {
        authUserId = (window as any).authUserId;
        initialLoad();
    });

    $: levelTitle = (context: LevelContext) => `${context.userName}'s Level ${context.currentLevel}`;
</script>

<div class="p-3">
    <h5 class="mb-3 font-bold">Referral Network</h5>
    <div class="md:flex">
        <h6 class="mr-4 mt-2 flex items-center text-gray-500">
            <span class="mr-2">
                <UsersIcon type="" />
            </span>
            <div>Direct Referrals: {directReferralsCount}</div>
        </h6>
        <h6 class="mr-4 mt-2 flex items-center text-gray-500">
            <span class="mr-2">
                <ArrowTrendingDown />
            </span>
            <div>Network Levels: {referralTreeDepth ?? 0}</div>
        </h6>
    </div>
</div>

<hr class="m-0" />

<div class="p-3">
    {#if directReferralsCount <= 0}
        <div class="mt-16 py-4 text-center">
            <h2 class="text-2xl font-extrabold">No Referrals Yet</h2>
            <p>
                You do not have any referred accounts. <br />
                <a href="/markets" class="text-blue-500 hover:underline">Buy some</a>
                on the Market or
                <a href="/my/settings/referrals" class="text-blue-500 hover:underline">refer some yourself</a>
                to change that.
            </p>
        </div>
    {:else}
        <div class="flex flex-col items-center justify-center">
            {#if referrer}
                <Card.Root class="w-2/3 md:w-2/4 lg:w-1/4">
                    <Card.Header class="flex flex-row items-center gap-2 p-3">
                        <img src="{referrer.avatar}" alt="{referrer.name}" class="mr-2 h-10 w-10 rounded-full" />
                        <div class="">
                            <Card.Title class="">{truncateName(referrer.name)}</Card.Title>
                            <Card.Description class="mb-0 text-center">
                                <a
                                    href="/{referrer.username}/network"
                                    target="_blank"
                                    class="text-bmn-500 hover:underline">Open network</a
                                >
                            </Card.Description>
                        </div>
                    </Card.Header>
                </Card.Root>
                <div class="h-8 w-px bg-gray-300 dark:bg-gray-700"></div>
            {/if}

            <Card.Root class="w-2/3 md:w-2/4 lg:w-1/4">
                <Card.Header class="flex flex-row items-center gap-2 p-3">
                    <img src="{networkOwner.avatar}" alt="{networkOwner.name}" class="mr-2 h-10 w-10 rounded-full" />
                    <div>
                        <Card.Title>{truncateName(networkOwner.name)}</Card.Title>
                        <Card.Description class="mb-0 text-center">
                            <a href="/{networkOwner.username}" target="_blank" class="text-bmn-500 hover:underline"
                                >View profile</a
                            >
                        </Card.Description>
                    </div>
                </Card.Header>
            </Card.Root>
            <div class="h-8 w-px bg-gray-300 dark:bg-gray-700"></div>
        </div>

        {#if isInitialLoading}
            <div class="p-3 text-center text-sm">Loading..</div>
        {:else if $levelContexts.length > 0}
            {#each $levelContexts as context, index (index)}
                <div>
                    {#if context.minimized}
                        <Card.Root class="mx-auto w-2/3 md:w-2/4 lg:w-1/4">
                            <Card.Header class="flex flex-row items-center gap-2 p-3">
                                <img
                                    src="{context.selectedUser?.avatar || context.referrals.nodes[0]?.avatar}"
                                    alt="{context.selectedUser?.name || context.userName}"
                                    class="mr-2 h-10 w-10 rounded-full"
                                />
                                <div>
                                    <Card.Title
                                        >{truncateName(context.selectedUser?.name || context.userName)}</Card.Title
                                    >
                                    <Card.Description class="mb-0 text-center">
                                        <a
                                            href="/{context.selectedUser?.username ||
                                                context.referrals.nodes[0]?.username}"
                                            target="_blank"
                                            class="text-bmn-500 hover:underline"
                                        >
                                            View profile
                                        </a>
                                    </Card.Description>
                                </div>
                            </Card.Header>
                        </Card.Root>
                    {:else}
                        <Card.Root class="w-full">
                            <Card.Header class="flex p-0 px-3 pt-2">
                                <Card.Title class="m-0 flex w-full items-center justify-between">
                                    <span>
                                        {levelTitle(context)}
                                        <span class="ml-2 text-sm font-normal text-gray-500"> select a user </span>
                                    </span>
                                    <DropdownMenu.Root>
                                        <DropdownMenu.Trigger>
                                            <div class="p-1">
                                                <EllipsisHorizontalIcon />
                                            </div>
                                        </DropdownMenu.Trigger>
                                        <DropdownMenu.Content>
                                            {#if index !== 0}
                                                <!-- Only show 'Close' option if it's not the first level -->
                                                <DropdownMenu.Item on:click="{() => closeLevel(index)}"
                                                    >Close</DropdownMenu.Item
                                                >
                                            {/if}
                                            <DropdownMenu.Item on:click="{showStats}">Stats</DropdownMenu.Item>
                                        </DropdownMenu.Content>
                                    </DropdownMenu.Root>
                                </Card.Title>
                            </Card.Header>
                            <Card.Content class="p-2">
                                <div class="flex gap-3 overflow-x-auto">
                                    {#each context.referrals.nodes as node (node.id)}
                                        <DropdownMenu.Root>
                                            <DropdownMenu.Trigger class="w-32 flex-shrink-0">
                                                <Card.Root>
                                                    <div class="p-2 text-center">
                                                        <img
                                                            src="{node.avatar}"
                                                            alt="{node.name}"
                                                            class="mx-auto mb-2 h-12 w-12 rounded-full"
                                                        />
                                                        <div class="text-sm font-semibold">
                                                            {truncateName(node.name, 8)}
                                                        </div>
                                                    </div>
                                                    <div class="flex justify-around p-0">
                                                        <div class="p-1">
                                                            <UsersIcon type="" />
                                                        </div>
                                                        <div class="p-1">
                                                            <ArrowTrendingDown />
                                                        </div>
                                                    </div>
                                                </Card.Root>
                                            </DropdownMenu.Trigger>
                                            <DropdownMenu.Content>
                                                <DropdownMenu.Item on:click="{() => visitProfile(node.username)}">
                                                    Visit Profile
                                                </DropdownMenu.Item>
                                                <DropdownMenu.Item
                                                    on:click="{() => loadNextLevel(node)}"
                                                    disabled="{node.isLeaf}"
                                                >
                                                    Load Referrals
                                                </DropdownMenu.Item>
                                            </DropdownMenu.Content>
                                        </DropdownMenu.Root>
                                    {/each}
                                </div>
                            </Card.Content>
                        </Card.Root>
                    {/if}
                    <div class="mx-auto h-8 w-px bg-gray-300 dark:bg-gray-700"></div>
                </div>
            {/each}
        {/if}

        {#if $levelContexts.length > 0 && !$levelContexts[$levelContexts.length - 1].minimized}
            <div class=" flex justify-center">
                {#if $errorState.type === 'info' && $errorState.message === 'No more levels to load.'}
                    <p class="text-sm text-gray-500 sm:text-base">{$errorState.message}</p>
                {:else}
                    <Button
                        variant="outline"
                        on:click="{loadLevelBelow}"
                        disabled="{$isLoading}"
                        class="px-2 py-1 text-sm sm:px-4 sm:py-2 sm:text-base"
                    >
                        {$isLoading ? 'Loading...' : 'Load level below'}
                    </Button>
                {/if}
            </div>
        {/if}

        {#if $errorState.type === 'error'}
            <div class="relative mt-4 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
                <strong class="font-bold">Error!</strong>
                <span class="block sm:inline">{$errorState.message}</span>
            </div>
        {/if}
    {/if}
</div>
